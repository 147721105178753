import AbstractRequest from '../AbstractRequest';
import { ApiMethod } from '../constants';
import { ApiResponseCheckIfFavorited } from '../types/business/businessCompany';

export class BusinessCompanyRequest extends AbstractRequest {
  get apiResource(): string {
    return 'business/company';
  }

  get dynamicIds(): Record<string, string> {
    return {
      id: '{id}',
    };
  }

  get routes(): Record<string, string> {
    return {
      CHECK_IF_FAVORITED: `/${this.dynamicIds.id}/check-if-favorited`,
      FAVOURITE: `/${this.dynamicIds.id}/favourite`,
      UNFAVOURITE: `/${this.dynamicIds.id}/unfavourite`,
    };
  }

  async checkIfFavorited(id: string): Promise<ApiResponseCheckIfFavorited> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.CHECK_IF_FAVORITED!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async favouriteBusinessCompany(id: string): Promise<void> {
    await this.request(
      ApiMethod.POST,
      this.routes.FAVOURITE!.replace(this.dynamicIds.id!, id)
    );
  }

  async unfavouriteBusinessCompany(id: string): Promise<void> {
    await this.request(
      ApiMethod.POST,
      this.routes.UNFAVOURITE!.replace(this.dynamicIds.id!, id)
    );
  }
}

export const businessCompanyRequest = new BusinessCompanyRequest();
