import { JSX, SVGProps } from 'react';

export default function ItIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.4 0H3.6C2.64522 0 1.72955 0.379285 1.05442 1.05442C0.379285 1.72955 0 2.64522 0 3.6V15.6C0 16.5548 0.379285 17.4705 1.05442 18.1456C1.72955 18.8207 2.64522 19.2 3.6 19.2H7.2V21.6H3.6C3.28174 21.6 2.97652 21.7264 2.75147 21.9515C2.52643 22.1765 2.4 22.4817 2.4 22.8C2.4 23.1183 2.52643 23.4235 2.75147 23.6485C2.97652 23.8736 3.28174 24 3.6 24H20.4C20.7183 24 21.0235 23.8736 21.2485 23.6485C21.4736 23.4235 21.6 23.1183 21.6 22.8C21.6 22.4817 21.4736 22.1765 21.2485 21.9515C21.0235 21.7264 20.7183 21.6 20.4 21.6H16.8V19.2H20.4C21.3548 19.2 22.2705 18.8207 22.9456 18.1456C23.6207 17.4705 24 16.5548 24 15.6V3.6C24 2.64522 23.6207 1.72955 22.9456 1.05442C22.2705 0.379285 21.3548 0 20.4 0ZM14.4 21.6H9.6V19.2H14.4V21.6ZM21.6 15.6C21.6 15.9183 21.4736 16.2235 21.2485 16.4485C21.0235 16.6736 20.7183 16.8 20.4 16.8H3.6C3.28174 16.8 2.97652 16.6736 2.75147 16.4485C2.52643 16.2235 2.4 15.9183 2.4 15.6V14.4H21.6V15.6ZM21.6 12H2.4V3.6C2.4 3.28174 2.52643 2.97652 2.75147 2.75147C2.97652 2.52643 3.28174 2.4 3.6 2.4H20.4C20.7183 2.4 21.0235 2.52643 21.2485 2.75147C21.4736 2.97652 21.6 3.28174 21.6 3.6V12Z"
        fill="#00AFB9"
      />
    </svg>
  );
}
