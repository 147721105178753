import AllDomainsIcon from 'assets/icons/domains/AllDomainsIcon';
import FinanceIcon from 'assets/icons/domains/FinanceIcon';
import HrIcon from 'assets/icons/domains/HrIcon';
import ItIcon from 'assets/icons/domains/ITIcon';
import LawIcon from 'assets/icons/domains/LawIcon';
import MarketingIcon from 'assets/icons/domains/MarketingIcon';
import SalesIcon from 'assets/icons/domains/SalesIcon';
import i18n from 'i18next';

export const businessDomainTabs = () => [
  {
    icon: <AllDomainsIcon />,
    label: i18n.t(`domains.all`),
    value: '',
    isChecked: false,
  },
  {
    icon: <ItIcon />,
    label: i18n.t(`domains.it`),
    value: 'it',
    isChecked: false,
  },
  {
    icon: <SalesIcon />,
    label: i18n.t(`domains.sales`),
    value: 'sales',
    isChecked: false,
  },
  {
    icon: <MarketingIcon />,
    label: i18n.t(`domains.marketing`),
    value: 'marketing',
    isChecked: false,
  },
  {
    icon: <HrIcon />,
    label: i18n.t(`domains.hr`),
    value: 'hr',
    isChecked: false,
  },
  {
    icon: <FinanceIcon />,
    label: i18n.t(`domains.finance`),
    value: 'finance',
    isChecked: false,
  },
  {
    icon: <LawIcon />,
    label: i18n.t(`domains.law`),
    value: 'law',
    isChecked: false,
  },
];
