import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import {
  ApiResponseGetBusinessPackages,
  BusinessPackage,
} from 'api/types/business/businessPackage';
import { fileRequest } from 'api/FileRequest';
import { businessPackageRequest } from '../../business/BusinessPackageRequest';
import { queryKeys } from '../../constants/queryKeys';
import { BusinessDashboardOptions } from './types';

function useGetBusinessPackages({
  limit = 10,
  enabled = true,
  domain,
  searchText,
}: BusinessDashboardOptions = {}) {
  const QUERY_KEY = [
    queryKeys.businessPackages,
    { limit, domain, searchText, enabled },
  ];

  const selectData = (data: InfiniteData<ApiResponseGetBusinessPackages>) => ({
    pages: data.pages
      .flatMap((res) => res.results)
      .map((element: BusinessPackage) => ({
        ...element,
        presentationPhoto: fileRequest.getPublicUrl(element.presentationPhoto),
        presentationVideo: fileRequest.getPublicUrl(element.presentationVideo),
        company: {
          name: element.company.name,
          logo: fileRequest.getPublicUrl(element.company.presentationPhoto),
        },
        videoThumbnail: element.presentationVideo?.thumbnailPath
          ? fileRequest.getPublicUrl({
              ...element.presentationVideo,
              path: element.presentationVideo.thumbnailPath,
            })
          : undefined,
      })),
    pageParams: data.pageParams,
  });

  return useInfiniteQuery({
    queryKey: QUERY_KEY,
    queryFn: ({ pageParam }) =>
      businessPackageRequest.getBusinessPackages({
        offset: pageParam as number,
        limit,
        domain,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (
        !lastPage?.results ||
        lastPage.results.length === 0 ||
        allPages.length * limit >= lastPage.total ||
        lastPage.total <= limit
      ) {
        return undefined;
      }
      return (lastPageParam as number) + limit;
    },
    select: selectData,
    enabled,
  });
}

export default useGetBusinessPackages;
