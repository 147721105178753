import { OnboardingStatus } from '../../api/types/user';
import { AuthState } from './auth';
import { ViewAppRole } from './appViewRole';
import { AppModeView } from './appMode';

export const isAuthenticatedSelector = ({ auth }: { auth: AuthState }) =>
  auth.isAuthenticated;

export const authUserIdSelector = ({ auth }: { auth: AuthState }) =>
  auth.user.id;

export const authUserRoleSelector = ({ auth }: { auth: AuthState }) =>
  auth.user.role?.name || '';

export const authUserSelector = ({ auth }: { auth: AuthState }) => auth.user;

export const authUserHasReferralAccountSelector = ({
  auth,
}: {
  auth: AuthState;
}) => auth.user.hasReferralAccount;

export const authUserOnboardingStatusSelector = ({
  auth,
}: {
  auth: AuthState;
}) => auth.user.onboardingStatus;

export const authUserOnboardingIsCompletedSelector = ({
  auth,
}: {
  auth: AuthState;
}) => auth.user.onboardingStatus === OnboardingStatus.ProfilePictureSet;

export const authUserEmailIsVerifiedSelector = ({
  auth,
}: {
  auth: AuthState;
}) => Boolean(auth.user.emailIsVerified);

export const authUserOnboardingIsSkipedSelector = ({
  auth,
}: {
  auth: AuthState;
}) => Boolean(auth.user.isOnboardingSkipped);

export const appViewRole = ({ appRole }: { appRole: ViewAppRole }) =>
  appRole.role;

export const authUserMessagesCount = ({ auth }: { auth: AuthState }) =>
  auth.user.unread.messages;

export const authUserNewsCount = ({ auth }: { auth: AuthState }) =>
  auth.user.unread.notifications;

export const isAuthUserProfilePicCompressing = ({
  auth,
}: {
  auth: AuthState;
}) =>
  auth.user.profilePicture?.extension &&
  auth.user.profilePicture.extension !== '.webp';

export const appModeSelector = (mode: { appMode: AppModeView }) => mode.appMode;
