import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppMode } from 'helpers/constants/enums';

export const setAppMode = createAsyncThunk(
  'appMode/set_appMode',
  (appMode: AppMode) => appMode
);

export interface AppModeView {
  appMode: AppMode;
}

const initialState: AppModeView = {
  appMode: AppMode.INDIVIDUAL,
};

const appModeSlice = createSlice({
  name: 'appMode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setAppMode.fulfilled, (state, action) => {
      state.appMode = action.payload;
    });
  },
});

export default appModeSlice.reducer;
