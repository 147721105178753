import BusinessDasboard from 'components/smart/pages/businessPages/BusinessDasboard';
import Dashboard from 'components/smart/pages/Dashboard';
import { Route } from './types';
import PATH from './paths';

let routes: Route[] = [];

const routeFiles = require.context(
  './',
  true,
  /^(?!.\/index|.\/paths|.\/patterns|.\/types|routes).*\.ts$/
);

routeFiles.keys().forEach((routeFile: string) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const dashboard: Route = {
  path: PATH.INDEX,
  component: Dashboard,
  isProtected: true,
  hasMenuBar: true,
};

const businessDasboard: Route = {
  path: PATH.BUSINESS_INDEX,
  component: BusinessDasboard,
  isProtected: true,
  hasMenuBar: true,
};

export default [...routes, dashboard, businessDasboard];
