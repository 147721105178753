import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  @media only screen and (max-width: 480px) {
    gap: 12px;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  @media only screen and (max-width: 480px) {
    gap: 12px;
  }
`;

export const MenuItemsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  padding: 0 40px;
  @media only screen and (max-width: 480px) {
    gap: 12px;
  }
`;

export const LogoutContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 15px;
  padding: 0 40px;
  @media only screen and (max-width: 480px) {
    gap: 12px;
  }
`;

export const LogoutMainContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 480px) {
    gap: 12px;
  }
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  @media only screen and (max-width: 480px) {
    align-items: center;
    gap: 12px;
  }
`;

export const NameContainer = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(6)};
  color: ${({ theme }) => theme.palette.main.black};
  font-style: normal;
  @media only screen and (max-width: 480px) {
    text-align: center;
  }
  @media only screen and (max-width: 480px) {
    font-size: ${({ theme }) => theme.typography.pxToRem(0)};
    line-height: ${({ theme }) => theme.typography.pxToRem(4)};
  }
`;

export const ProfileAccountSection = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ gap }) => `${gap}px`};
`;

export const HorizontalSeparator = styled.div<{ isBusiness: boolean }>`
  width: 100%;
  max-width: 400px;
  height: 2px;
  background: ${({ theme, isBusiness }) =>
    isBusiness ? theme.palette.main.black : theme.palette.main.marine};
`;

export const InformationSection = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ gap }) => `${gap}px`};
  @media only screen and (max-width: 480px) {
    gap: ${({ gap }) => `${gap - 3}px`};
  }
`;

export const EmailContainer = styled(NameContainer)`
  font-style: italic;
`;
