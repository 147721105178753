import AbstractRequest from '../AbstractRequest';
import { ApiMethod } from '../constants';
import {
  ApiResponseGetBusinessPackages,
  ApiResponseGetBusinessPackage,
  ApiResponseIsRequestable,
  ApiResponseRequestToJoin,
  ApiResponseCheckIfFavorited,
} from '../types/business/businessPackage';

export class BusinessPackageRequest extends AbstractRequest {
  get apiResource(): string {
    return 'business/package';
  }

  get dynamicIds(): Record<string, string> {
    return {
      id: '{id}',
      experienceSlug: '{experienceSlug}',
    };
  }

  get routes(): Record<string, string> {
    return {
      GET_BUSINESS_PACKAGES: `${this.apiRoute}`,
      GET_BUSINESS_BY_SLUG: `/${this.dynamicIds.experienceSlug}`,
      GET_BUSINESS_FAVORITES: `/favorites`,
      IS_REQUESTABLE: `/${this.dynamicIds.id}/is-requestable`,
      FAVOURITE: `/${this.dynamicIds.id}/favourite`,
      UNFAVOURITE: `/${this.dynamicIds.id}/unfavourite`,
      REQUEST_TO_JOIN: `/${this.dynamicIds.id}/request-to-join`,
      CHECK_IF_FAVORITED: `/${this.dynamicIds.id}/check-if-favorited`,
    };
  }

  async getBusinessPackages(queryOptions: {
    limit: number;
    offset: number;
    domain?: string;
    searchText?: string;
  }): Promise<ApiResponseGetBusinessPackages> {
    const queryParams = [
      { key: 'limit', value: `${queryOptions.limit}` },
      { key: 'offset', value: `${queryOptions.offset}` },
    ];
    if (queryOptions.domain) {
      queryParams.push({ key: 'domain', value: queryOptions.domain });
    }
    if (queryOptions.searchText) {
      queryParams.push({ key: 'searchText', value: queryOptions.searchText });
    }
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_BUSINESS_PACKAGES + this.buildQueryParams(queryParams)
    );

    return result.data;
  }

  async getBusinessPackageBySlug(
    experienceSlug: string
  ): Promise<ApiResponseGetBusinessPackage> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_BUSINESS_BY_SLUG!.replace(
        this.dynamicIds.experienceSlug!,
        experienceSlug
      )
    );

    return result.data;
  }

  async getBusinessFavorites(
    limit = 10,
    offset = 0
  ): Promise<ApiResponseGetBusinessPackages> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_BUSINESS_FAVORITES + this.buildQueryParams(queryParams)
    );

    return result.data;
  }

  async isBusinessPackageRequestable(
    id: string
  ): Promise<ApiResponseIsRequestable> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.IS_REQUESTABLE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async favouriteBusinessPackage(id: string): Promise<void> {
    await this.request(
      ApiMethod.POST,
      this.routes.FAVOURITE!.replace(this.dynamicIds.id!, id)
    );
  }

  async unfavouriteBusinessPackage(id: string): Promise<void> {
    await this.request(
      ApiMethod.POST,
      this.routes.UNFAVOURITE!.replace(this.dynamicIds.id!, id)
    );
  }

  async requestToJoinBusinessPackage(
    id: string
  ): Promise<ApiResponseRequestToJoin> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REQUEST_TO_JOIN!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async checkIfFavorited(id: string): Promise<ApiResponseCheckIfFavorited> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.CHECK_IF_FAVORITED!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }
}

export const businessPackageRequest = new BusinessPackageRequest();
