import Modal from 'components/dumb/atomics/Modals/Modal';
import { useTheme } from 'styled-components';
import SearchFilterForm, {
  BusinessFilters,
  ExperienceUrlFilters,
} from './SearchFilterForm';
import { Option } from '../../../atomics/DropdownInput';

interface SearchFilterModalProps {
  isOpen: boolean;
  filters: ExperienceUrlFilters;
  requestIsLoading: boolean;
  countries: Option[] | [];
  cities: Option[] | [];
  activeExperience: number;
  handleSelectedCountry: CallbackFunction;
  handleSearchedCity: CallbackFunction;
  onApplyFilters: (data: BusinessFilters) => void;
  onClose: CallbackFunction;
  onClearFilters: CallbackFunction;
}

export default function SearchFilterModal({
  isOpen,
  filters,
  requestIsLoading,
  countries,
  cities,
  activeExperience,
  handleSelectedCountry,
  handleSearchedCity,
  onApplyFilters,
  onClose,
  onClearFilters,
}: SearchFilterModalProps) {
  const theme = useTheme();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasCloseIcon
      backgroundColor={`${theme.palette.business.main.yellowGreen}`}
    >
      {isOpen && countries.length > 0 && (
        <SearchFilterForm
          filters={filters}
          requestIsLoading={requestIsLoading}
          countries={countries}
          cities={cities}
          activeExperiences={activeExperience}
          handleSelectedCountry={handleSelectedCountry}
          handleSearchedCity={handleSearchedCity}
          onApplyFilters={onApplyFilters}
          onClose={onClose}
          onClearFilters={onClearFilters}
        />
      )}
    </Modal>
  );
}
