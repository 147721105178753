import styled from 'styled-components';

export const ToggleSwitch = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.palette.main.white};
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

export const ToggleOption = styled.div<{ isActive: boolean }>`
  flex: 1;
  text-align: center;
  line-height: 30px;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  color: ${(props) =>
    props.isActive
      ? props.theme.palette.main.white
      : props.theme.palette.main.turquoise};
  z-index: 2;
  cursor: pointer;
`;

export const ActiveIndicator = styled.div<{
  activeIndex: number;
  color: string;
}>`
  position: absolute;
  width: calc(100% / 2);
  height: 100%;
  border-radius: 30px;
  transition: transform 0.3s ease;
  transform: translateX(${(props) => props.activeIndex * 100}%);
  background-color: ${(props) => props.color};
  z-index: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;
