import BusinessExperienceCard, {
  ActionButton,
} from 'components/dumb/atomics/business/BusinessExerienceCard';
import { ExperienceType } from 'helpers/constants/enums';
import { ExperienceCardType } from 'uiModels/enums';
import { BusinessExperience, Experience } from 'uiModels/interfaces';
import { BusinessDomain } from 'uiModels/types';

export const mappedBusinesExperiences = (experiences: BusinessExperience[]) =>
  experiences?.map((experience: BusinessExperience) => (
    <BusinessExperienceCard
      id={experience.id}
      key={experience.id}
      title={experience.title}
      presentationPhoto={experience.presentationPhoto}
      location={experience.city}
      domain={experience.domain as BusinessDomain}
      durationHours={experience.durationHours}
      durationMinutes={experience.durationMinutes}
      startDate={experience.startDate}
      endDate={experience.endDate}
      cardType={ExperienceCardType.Permanent}
      typeOfExperience={ExperienceType.HANDS_ON}
      actionButton={ActionButton.DISCOVER}
      href={experience.href}
      company={{ name: experience.company.name, logo: experience.company.logo }}
    />
  ));
