import { MouseEvent, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExperienceCardType,
  ExperienceStatus,
  RequestStatus,
} from 'uiModels/enums';
import { Link } from 'react-router-dom';
import theme from 'styles/theme/classic';
import { BusinessDomain } from 'uiModels/types';
import LocationPinIcon from 'assets/icons/profileIcons/LocationPinIcon';
import TimeClockIcon from 'assets/icons/experience/details/TimeClockIcon';
import ExploreIcon from 'assets/icons/buttonIcons/ExploreIcon';
import DateTimeUtils from 'helpers/classes/DateTimeUtils';
import { DurationFormat } from 'uiModels/enums/format';
import { renderExperienceTypeLabel } from 'helpers/functions/experience/experienceTypes';
import { CompanyBasic } from 'uiModels/interfaces';
import * as SC from './styles';
import mediaPlaceholder from '../../../../../assets/images/placeholders/MediaPlaceholderLogoSmall.webp';
import avatarPlaceholder from '../../../../../assets/images/placeholders/ProfilePicEmpty.webp';

import { getBusinessDomainIcon, renderExperienceTypeIcon } from './helpers';
import ExperienceOverlay from '../../ExperienceCard/ExperienceOverlay';

export enum ActionButton {
  DISCOVER = 'discover',
  SETTINGS = 'settings',
}

export interface ExperienceCardProps {
  id: string;
  company: CompanyBasic;
  title: string;
  presentationPhoto: string;
  domain: BusinessDomain;
  durationHours: number | null;
  durationMinutes: number | null;
  startDate: Date;
  endDate: Date;
  location: string;
  typeOfExperience: string;
  cardType?: ExperienceCardType;
  showUser?: boolean;
  actionButton?: ActionButton.DISCOVER | ActionButton.SETTINGS;
  status?: string;
  isShadowBanned?: boolean;
  href?: string;
  onSettingsClick?: CallbackFunction;
  onExploreClick?: CallbackFunction;
}

export default function BusinessExperienceCard({
  id,
  company,
  title,
  presentationPhoto,
  domain,
  status,
  durationHours,
  durationMinutes,
  startDate,
  endDate,
  location,
  typeOfExperience,
  cardType = ExperienceCardType.Regular,
  showUser = true,
  actionButton,
  isShadowBanned = false,
  href,
  onExploreClick,
  onSettingsClick,
}: ExperienceCardProps) {
  const { t } = useTranslation();

  const effectiveStatus = isShadowBanned
    ? ExperienceStatus.ShadowBanned
    : status;

  const hasActionButton =
    actionButton === ActionButton.DISCOVER ||
    actionButton === ActionButton.SETTINGS;

  const hasLink = href && href.length > 0;

  const handleExperienceClickAction = () => {
    if (hasLink) {
      return;
    }
    if (onExploreClick) {
      onExploreClick(id);
    }
  };

  const getCompanyDetails = () => (
    <SC.UserDetails>
      <SC.UserDetailsContent>
        <SC.UserAvatarContainer>
          <SC.UserAvatar
            src={company?.logo || avatarPlaceholder}
            onError={(e: SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = avatarPlaceholder;
            }}
          />
        </SC.UserAvatarContainer>
        <SC.UserNameWrapper>
          <SC.UserNameContent>
            <SC.CompanyNameText>{`${company.name}`}</SC.CompanyNameText>
          </SC.UserNameContent>
        </SC.UserNameWrapper>
      </SC.UserDetailsContent>
    </SC.UserDetails>
  );

  const DiscoverButton = (
    <SC.StyledButton
      color={theme.palette.main.marine}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (!hasLink) {
          e.stopPropagation();
          handleExperienceClickAction();
        }
      }}
    >
      {t('general.discover')}
      <SC.ButtonIconContainer>
        <ExploreIcon />
      </SC.ButtonIconContainer>
    </SC.StyledButton>
  );

  return (
    <SC.Container
      $extendedCardHeight={hasActionButton}
      $showUser={showUser}
      onClick={handleExperienceClickAction}
    >
      {hasLink && (
        <SC.LinkOverlay to={href} state={{ isNavigatedFromApp: true }} />
      )}
      <SC.CardContent>
        <SC.ImageContainer>
          <ExperienceOverlay
            currentStatus={effectiveStatus as ExperienceStatus | RequestStatus}
          />
          <SC.Overlay />
          <SC.Image
            src={presentationPhoto}
            onError={(e: SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = mediaPlaceholder;
            }}
          />
          <SC.ImageDateContainer>
            <SC.ExperienceTitle>{title}</SC.ExperienceTitle>
          </SC.ImageDateContainer>
        </SC.ImageContainer>
        <SC.ExperienceDetailsContainer>
          {getCompanyDetails()}
          {showUser ? <SC.Divider /> : null}
          <SC.ExperienceDetailsContent>
            <SC.DetailRow>
              <SC.Detail>
                <SC.ColoredIconContainer>
                  <LocationPinIcon />
                </SC.ColoredIconContainer>
                <SC.DetailText>{location}</SC.DetailText>
              </SC.Detail>
              <SC.Detail>
                <SC.ColoredIconContainer>
                  <TimeClockIcon />
                </SC.ColoredIconContainer>
                <SC.DurationDetailText>
                  {cardType === ExperienceCardType.Permanent
                    ? DateTimeUtils.formatDuration(
                        t,
                        durationHours,
                        durationMinutes,
                        DurationFormat.SHORT
                      )
                    : DateTimeUtils.formatTimeRangeToDuration(
                        t,
                        startDate,
                        endDate,
                        DurationFormat.SHORT
                      )}
                </SC.DurationDetailText>
              </SC.Detail>
            </SC.DetailRow>
            <SC.DetailRow>
              <SC.Detail>
                <SC.ColoredIconContainer>
                  {getBusinessDomainIcon(domain)}
                </SC.ColoredIconContainer>
                <SC.DetailText>{t(`domains.${domain}`)}</SC.DetailText>
              </SC.Detail>
              <SC.Detail>
                {renderExperienceTypeIcon(typeOfExperience)}
                <SC.DetailText>
                  {renderExperienceTypeLabel(typeOfExperience)}
                </SC.DetailText>
              </SC.Detail>
            </SC.DetailRow>
          </SC.ExperienceDetailsContent>
          {hasActionButton && (
            <SC.ButtonContainer>
              {actionButton === ActionButton.DISCOVER &&
                (onExploreClick || hasLink) &&
                (hasLink ? (
                  <Link
                    to={href ?? ''}
                    style={{ width: '100%' }}
                    state={{ isNavigatedFromApp: true }}
                  >
                    {DiscoverButton}
                  </Link>
                ) : (
                  DiscoverButton
                ))}
              {actionButton === ActionButton.SETTINGS && onSettingsClick ? (
                <SC.StyledButton
                  color={theme.palette.main.lightGray}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onSettingsClick();
                  }}
                >
                  {t('addExperience.btnSettings')}
                </SC.StyledButton>
              ) : null}
            </SC.ButtonContainer>
          )}
        </SC.ExperienceDetailsContainer>
      </SC.CardContent>
    </SC.Container>
  );
}
