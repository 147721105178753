import i18n from 'i18next';

export const getDomainOptions = () => [
  {
    label: i18n.t(`domains.business`),
    value: 'business',
  },
  {
    label: i18n.t(`domains.lifestyle`),
    value: 'lifestyle',
  },
  {
    label: i18n.t(`domains.experts`),
    value: 'experts',
  },
  {
    label: i18n.t(`domains.technology`),
    value: 'technology',
  },
  {
    label: i18n.t(`domains.creativity`),
    value: 'creativity',
  },
  {
    label: i18n.t(`domains.traveling`),
    value: 'traveling',
  },
  {
    label: i18n.t(`domains.adventures`),
    value: 'adventures',
  },
  {
    label: i18n.t(`domains.parenting`),
    value: 'parenting',
  },
  {
    label: i18n.t(`domains.healthcare`),
    value: 'healthcare',
  },
  {
    label: i18n.t(`domains.mindfulness`),
    value: 'mindfulness',
  },
  {
    label: i18n.t(`domains.small_talk`),
    value: 'small_talk',
  },
  {
    label: i18n.t(`domains.extras`),
    value: 'extras',
  },
];

export const getBusinessDomainOptions = () => [
  {
    label: i18n.t(`domains.it`),
    value: 'it',
  },
  {
    label: i18n.t(`domains.sales`),
    value: 'sales',
  },
  {
    label: i18n.t(`domains.marketing`),
    value: 'marketing',
  },
  {
    label: i18n.t(`domains.hr`),
    value: 'hr',
  },
  {
    label: i18n.t(`domains.finance`),
    value: 'finance',
  },
  {
    label: i18n.t(`domains.law`),
    value: 'law',
  },
];
