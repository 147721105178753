import { JSX, SVGProps } from 'react';

export default function MarketingIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="21"
      height="29"
      viewBox="0 0 21 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.1821 2.40065C15.9749 1.40362 14.5612 0.689788 13.0443 0.311248C11.5273 -0.0672919 9.94538 -0.100996 8.41381 0.212596C6.3745 0.626246 4.5035 1.63888 3.03822 3.12202C1.57294 4.60516 0.579408 6.49196 0.18369 8.54302C-0.105287 10.0809 -0.0527755 11.6638 0.337479 13.179C0.727734 14.6941 1.44612 16.1041 2.4414 17.3084C3.36507 18.3551 3.89451 19.6935 3.93778 21.0914V25.0457C3.93778 26.0944 4.35266 27.1002 5.09115 27.8418C5.82964 28.5834 6.83125 29 7.87564 29H13.1261C14.1705 29 15.1721 28.5834 15.9106 27.8418C16.6491 27.1002 17.064 26.0944 17.064 25.0457V21.3418C17.108 19.7985 17.6726 18.316 18.6654 17.1371C20.4047 14.9764 21.2247 12.2137 20.9469 9.44938C20.6692 6.68506 19.3162 4.14251 17.1821 2.37429V2.40065ZM14.4387 25.0457C14.4387 25.3953 14.3004 25.7305 14.0543 25.9777C13.8081 26.2249 13.4742 26.3638 13.1261 26.3638H7.87564C7.52751 26.3638 7.19364 26.2249 6.94748 25.9777C6.70131 25.7305 6.56302 25.3953 6.56302 25.0457V23.7276H14.4387V25.0457ZM16.6308 15.5026C15.311 17.0746 14.5409 19.0378 14.4387 21.0914H11.8135V17.1371C11.8135 16.7875 11.6752 16.4522 11.429 16.205C11.1829 15.9578 10.849 15.819 10.5009 15.819C10.1527 15.819 9.81888 15.9578 9.57272 16.205C9.32655 16.4522 9.18826 16.7875 9.18826 17.1371V21.0914H6.56302C6.52839 19.0722 5.78457 17.1302 4.46283 15.6081C3.59078 14.5588 3.00437 13.3006 2.76077 11.956C2.51717 10.6115 2.6247 9.22647 3.07288 7.93607C3.52106 6.64566 4.29458 5.4939 5.31803 4.59309C6.34148 3.69227 7.57992 3.07315 8.91261 2.79608C10.0579 2.55928 11.2414 2.58155 12.3771 2.86129C13.5128 3.14102 14.5722 3.6712 15.4784 4.41333C16.3846 5.15546 17.1149 6.09092 17.6162 7.15178C18.1175 8.21265 18.3772 9.3723 18.3766 10.5465C18.3863 12.3522 17.769 14.1047 16.6308 15.5026Z"
        fill="#00AFB9"
      />
    </svg>
  );
}
