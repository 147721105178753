import React, { useState } from 'react';
import { AppMode } from 'helpers/constants/enums';
import theme from 'styles/theme/classic';
import { useTranslation } from 'react-i18next';
import ToggleSwitch, { ToggleOption } from '../ToggleSwitch/ToggleSwitch';

interface AppModeSwitchProps {
  defaultMode?: AppMode;
  onModeChange?: (mode: AppMode) => void;
  onModeChangeToggleEnd?: (mode: AppMode) => void;
}

const AppModeSwitch: React.FC<AppModeSwitchProps> = ({
  defaultMode = AppMode.INDIVIDUAL,
  onModeChange,
  onModeChangeToggleEnd,
}) => {
  const [activeValue, setActiveValue] = useState<AppMode>(defaultMode);
  const { t } = useTranslation();

  const options: ToggleOption<AppMode>[] = [
    { label: t('general.individual'), value: AppMode.INDIVIDUAL },
    { label: t('general.business'), value: AppMode.BUSINESS },
  ];

  const getColor = (value: AppMode) => {
    switch (value) {
      case AppMode.INDIVIDUAL:
        return theme.palette.main.turquoise;
      case AppMode.BUSINESS:
        return theme.palette.business.main.darkGreen;
      default:
        return theme.palette.main.turquoise;
    }
  };

  const handleModeChange = (mode: AppMode) => {
    setActiveValue(mode);
    if (onModeChange) {
      onModeChange(mode);
    }
  };

  return (
    <ToggleSwitch
      activeValue={activeValue}
      options={options}
      onToggle={handleModeChange}
      onToggleEnd={onModeChangeToggleEnd}
      getColor={getColor}
    />
  );
};

export default AppModeSwitch;
