export const queryKeys = {
  activeExperiences: 'activeExperiences',
  cities: 'cities',
  completedExperiences: 'completedExperiences',
  countries: 'countries',
  cvData: 'cvData',
  domains: 'domains',
  explorerExperiences: 'explorerExperiences',
  explorerProfile: 'explorerProfile',
  experienceViewOne: 'experienceViewOne',
  experiences: 'experiences',
  favoritedExperience: 'favoritedExperience',
  favorites: 'favorites',
  hobbies: 'hobbies',
  invoices: 'invoices',
  languages: 'languages',
  meetingPlaces: 'meetingPlaces',
  mindFeedExperiences: 'mindFeedExperiences',
  myExperiences: 'myExperiences',
  popularPersons: 'popularPersons',
  referralAccount: 'referralAccount',
  referralAccountHistory: 'referralAccountHistory',
  referralAccountReferredUsers: 'referralAccountReferredUsers',
  requestsToJoin: 'requestsToJoin',
  reviews: 'reviews',
  users: 'users',
  userId: 'userId',
  packageGiveaway: 'packageGiveaway',
  businessPackages: 'businessPackages',
  businessPackage: 'businessPackage',
  businessFavorites: 'businessFavorites',
  businessPackageIsRequestable: 'businessPackageIsRequestable',
  businessPackageIsFavorited: 'businessPackageIsFavorited',
  businessCompanyIsFavorited: 'businessCompanyIsFavorited',
};
