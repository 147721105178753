import { lazy } from 'react';
import PATH from 'routes/paths';
import { Route } from 'routes/types';

const ConnectedBusinessExperienceViewOnePage = lazy(
  () =>
    import(
      '../../components/smart/pages/businessPages/experience/ConnectedBusinessExperienceViewOnePage'
    )
);

const ConnectedWaitingListPage = lazy(
  () =>
    import(
      '../../components/smart/pages/businessPages/experience/ConnectedWaitingListPage'
    )
);

const EXPERIENCE_VIEW_ONE: Route = {
  component: ConnectedBusinessExperienceViewOnePage,
  path: PATH.BUSINESS.EXPERIENCE_VIEW_ONE,
  isProtected: true,
  hasMenuBar: false,
};

const EXPERIENCE_WAITING_LIST: Route = {
  component: ConnectedWaitingListPage,
  path: PATH.BUSINESS.WAITING_LIST,
  isProtected: true,
  hasMenuBar: false,
};

export default [EXPERIENCE_VIEW_ONE, EXPERIENCE_WAITING_LIST];
