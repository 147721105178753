import { useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import useOnEndReached from 'hooks/useOnEndReached';
import useRedirectToExperienceView from 'hooks/useRedirectToExperienceView';
import { Helmet } from 'react-helmet';
import useGetSearchedUsers from 'api/hooks/user/useGetSearchedUsers';
import useGetPopularPersons from 'api/hooks/user/useGetPopularPersons';
import {
  BULK_REQUEST_LIMIT,
  DEBOUNCE_TIME,
  DEFAULT_CATEGORY_FILTER,
  DEFAULT_REQUEST_LIMIT,
} from 'api/constants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setAppMode } from 'redux/slices/appMode';
import {
  appModeSelector,
  appViewRole,
  authUserOnboardingIsSkipedSelector,
  authUserSelector,
} from 'redux/slices/selectors';
import { AppMode, SearchFilters } from 'helpers/constants/enums';
import useGetExperiences from 'api/hooks/experience/useGetExperiences';
import useExperiencesUrlParams from 'hooks/useExperiencesUrlParams';
import PATH from '../../../routes/paths';
import {
  contentCreatorSlug,
  experienceSlug,
  userId,
} from '../../../routes/paths/constants';
import ExperienceFeedPageLayout from '../../dumb/layouts/ExperienceFeedPageLayout';
import { AppRole } from '../../../api/types/user';
import useInAppNavigate from '../../../hooks/useInAppNavigate';
import { parseNumberString } from '../../../helpers/functions/numbers';
import useGetLanguages from '../../../api/hooks/languages/useGetLanguages';
import useGetCountries from '../../../api/hooks/country/useGetCountries';
import useGetCityByCountryId from '../../../api/hooks/country/useGetCityByCountryId';
import { Option } from '../../dumb/atomics/DropdownInput';
import mapToOption from '../../../helpers/functions/mapToOption';
import { Filters } from '../../dumb/layouts/FiltersModal/FiltersForm';
import { queryKeys } from '../../../api/constants/queryKeys';

export default function Dashboard() {
  const {
    urlParams,
    setUrlSearch,
    setUrlCategory,
    setFilterParamsToUrlFromObject,
    removeFilterParamsFromUrlFromObject,
    removeFilterParamsByKeysFromUrl,
    removeUrlSearch,
  } = useExperiencesUrlParams();
  const dispatch = useAppDispatch();

  const [offset, setOffset] = useState(0);
  const limit = DEFAULT_REQUEST_LIMIT;
  const debouncedSearchTextParam = useDebounce(urlParams.search, DEBOUNCE_TIME);
  const [selectedCountry, setSelectedCountry] = useState<string>(
    urlParams.country
  );
  const [searchedCity, setSearchedCity] = useState('');
  const debouncedCity = useDebounce(searchedCity, DEBOUNCE_TIME);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [searchFilter, setSearchFilter] = useState<SearchFilters>(
    (urlParams.category as SearchFilters) || SearchFilters.EXPERIENCES
  );
  const [domainTabFilter, setDomainTabFilter] = useState(
    DEFAULT_CATEGORY_FILTER
  );

  const authUser = useAppSelector(authUserSelector);
  const { navigate } = useInAppNavigate();

  const handleSetSearchFilters = (filter: SearchFilters) => {
    setSearchFilter(filter);
    setUrlCategory(filter);
    setOffset(0);
  };

  const handleSearch = (searchText: string) => {
    if (searchText.length === 0) {
      removeUrlSearch();
      return;
    }

    setUrlSearch(searchText);
    setOffset(0);
  };

  const handleDomainFilter = (domain: string) => {
    setDomainTabFilter(domain);
    setOffset(0);
  };

  const handleApplyFilters = (filtersData: Filters) => {
    const hasNonDefaultFilters = Object.values(filtersData).some(
      (filter) =>
        filter &&
        filter !== DEFAULT_CATEGORY_FILTER &&
        filter.value !== DEFAULT_CATEGORY_FILTER
    );

    setFilterParamsToUrlFromObject(filtersData);

    if (hasNonDefaultFilters) {
      setDomainTabFilter(DEFAULT_CATEGORY_FILTER);
    }

    setOffset(0);
  };

  const handleClearFilters = (data: Filters) => {
    removeFilterParamsFromUrlFromObject(data);
  };

  const handleSelectedCountry = (country: Option) => {
    setSelectedCountry(country.value);
    removeFilterParamsByKeysFromUrl(['city']);
  };

  const handleSearchedCity = (cityInput: string) => {
    setSearchedCity(cityInput);
  };

  const {
    data: searchedUsers,
    fetchNextPage: fetchMoreUsers,
    isFetching: fetchingUsers,
    isFetchingNextPage: fetchingMoreUsers,
  } = useGetSearchedUsers(
    {
      limit,
      searchText: debouncedSearchTextParam as string,
    },
    Boolean(debouncedSearchTextParam)
  );

  const { data: popularPersons, isFetching: fetchingPopularPersons } =
    useGetPopularPersons(
      {
        limit: -1,
        searchText: debouncedSearchTextParam as string,
      },
      Boolean(debouncedSearchTextParam)
    );

  const {
    data: pagedExperiences,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useGetExperiences(queryKeys.experiences, {
    limit,
    domain: urlParams.domain || domainTabFilter,
    type: urlParams.experienceType,
    minPrice: parseNumberString(urlParams.minPrice),
    maxPrice: parseNumberString(urlParams.maxPrice),
    currency: urlParams.currency,
    countryId: urlParams.country,
    cityId: urlParams.city,
    languageId: urlParams.language,
    searchText: debouncedSearchTextParam as string,
  });

  const { data: languageOptions, isLoading: loadingLanguages } =
    useGetLanguages(isFiltersOpen);
  const { data: countryOptions, isLoading: loadingCountries } =
    useGetCountries(isFiltersOpen);

  const { data: cityResponse } = useGetCityByCountryId(
    {
      id: selectedCountry ?? authUser?.city?.country.id ?? '',
      offset: 0,
      limit: BULK_REQUEST_LIMIT,
      searchText: debouncedCity as string,
    },
    !!selectedCountry
  );

  const handleNavigateProfile = (
    role: string,
    profileId: string,
    slug?: string
  ) => {
    switch (role) {
      case AppRole.ContentCreator:
        if (slug && slug.length > 0) {
          navigate(
            PATH.EXMINDER.VIEW_PROFILE_BY_SLUG.replace(contentCreatorSlug, slug)
          );
        } else {
          navigate(PATH.EXMINDER.VIEW_PROFILE.replace(userId, profileId));
        }
        break;
      case AppRole.Consumer:
        navigate(PATH.EXPLORER.VIEW_ONE.replace(userId, profileId));
        break;
      default:
        navigate(PATH.FAKE_INFLUENCER.VIEW_ONE.replace(userId, profileId));
        break;
    }
  };

  const isAtEnd = useOnEndReached();

  useEffect(() => {
    if (isAtEnd) {
      setOffset(offset + limit);
      fetchNextPage();
      if (searchFilter === SearchFilters.ACCOUNTS) {
        fetchMoreUsers();
      }
    }
  }, [searchFilter, isAtEnd]);

  useEffect(() => {
    if (!debouncedSearchTextParam) {
      setSearchFilter(SearchFilters.EXPERIENCES);
    }
  }, [debouncedSearchTextParam]);

  const appRole = useAppSelector(appViewRole);
  const isOnboardingSkipped = useAppSelector(
    authUserOnboardingIsSkipedSelector
  );

  useRedirectToExperienceView();

  const loading =
    isFetching ||
    isFetchingNextPage ||
    fetchingUsers ||
    fetchingPopularPersons ||
    fetchingMoreUsers ||
    loadingLanguages ||
    loadingCountries;

  return (
    <>
      <ExperienceFeedPageLayout
        currentAppMode={AppMode.INDIVIDUAL}
        handleChangeAppMode={(value) => {
          dispatch(setAppMode(value as AppMode));
        }}
        handleAppModeToggleEnd={() => {
          navigate(PATH.BUSINESS_INDEX);
        }}
        experiences={pagedExperiences?.pages?.map((experience) => ({
          ...experience,
          href: PATH.EXPERIENCE.VIEW_ONE_BY_SLUG.replace(
            contentCreatorSlug,
            experience.slug?.contentCreatorFullName ?? ''
          ).replace(experienceSlug, experience.slug?.packageTitle ?? ''),
        }))}
        filters={urlParams}
        activeDomainTab={domainTabFilter}
        searchFilter={searchFilter}
        searchText={debouncedSearchTextParam as string}
        users={[
          ...(popularPersons?.pages ?? []),
          ...(searchedUsers?.pages ?? []),
        ]}
        countries={mapToOption(countryOptions)}
        cities={mapToOption(cityResponse)}
        languages={mapToOption(languageOptions)}
        isExminder={appRole === AppRole.ContentCreator}
        isLoading={loading}
        requestIsLoading={isFetching}
        isTextSearched={Boolean(debouncedSearchTextParam)}
        isOnboardingSkipped={isOnboardingSkipped}
        currentSearchFilter={searchFilter}
        onSearchText={handleSearch}
        onDomainFilter={handleDomainFilter}
        onSelectSearchFilter={handleSetSearchFilters}
        onCreateAuctionClick={() => navigate(PATH.EXMINDER.MY_EXPERIENCES)}
        onCreateExperienceClick={() => navigate(PATH.EXPERIENCE.CREATE)}
        onTurnOnNotifications={() => navigate(PATH.ACCOUNT_SETTINGS.INDEX)}
        onBuildProfile={() => navigate(PATH.ONBOARDING.CHAT)}
        onSelectedCountry={handleSelectedCountry}
        onSearchedCity={handleSearchedCity}
        onOpenFilters={setIsFiltersOpen}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        onShowExminderProfileClick={(creatorSlug: string) => {
          navigate(
            PATH.EXMINDER.VIEW_PROFILE_BY_SLUG.replace(
              contentCreatorSlug,
              creatorSlug
            )
          );
        }}
        onUserFilterResultClick={(
          role: string,
          profileId: string,
          slug?: string
        ) => {
          handleNavigateProfile(role, profileId, slug);
        }}
      />
      <Helmet>
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
    </>
  );
}
