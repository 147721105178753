import { lazy } from 'react';
import PATH from 'routes/paths';
import { Route } from 'routes/types';

const ConnectedUpdateMissingInfoPage = lazy(
  () =>
    import(
      '../../components/smart/pages/businessPages/profileUpdate/ConnectedUpdateMissingInfoPage'
    )
);

const ConnectedUploadCvPage = lazy(
  () =>
    import(
      '../../components/smart/pages/businessPages/profileUpdate/ConnectedUploadCvPage'
    )
);

const ConnectedUploadVideoPage = lazy(
  () =>
    import(
      '../../components/smart/pages/businessPages/profileUpdate/ConnectedUploadVideoPage'
    )
);

const UPDATE_PROFILE_INFO: Route = {
  component: ConnectedUpdateMissingInfoPage,
  path: PATH.BUSINESS.PROFILE.MISSING_INFO,
  isProtected: true,
  hasMenuBar: false,
};

const UPLOAD_CV: Route = {
  component: ConnectedUploadCvPage,
  path: PATH.BUSINESS.PROFILE.UPLOAD_CV,
  isProtected: true,
  hasMenuBar: false,
};

const UPLOAD_VIDEO: Route = {
  component: ConnectedUploadVideoPage,
  path: PATH.BUSINESS.PROFILE.UPLOAD_VIDEO,
  isProtected: true,
  hasMenuBar: false,
};

export default [UPDATE_PROFILE_INFO, UPLOAD_CV, UPLOAD_VIDEO];
