import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getBusinessDomainOptions } from 'helpers/functions/getDomainOptions';

import * as SC from './styles';

import DropdownInput, { Option } from '../../../../atomics/DropdownInput';
import Button from '../../../../atomics/Button';
import classic from '../../../../../../styles/theme/classic';

export type ExperienceUrlFilters = {
  search: string;
  country: string;
  city: string;
  domain: string;
};

export interface BusinessFilters {
  search: string;
  country: Option;
  city: Option;
  domain: Option;
}

interface SearchFilterFormProps {
  filters: ExperienceUrlFilters;
  requestIsLoading: boolean;
  countries: Option[];
  cities: Option[];
  activeExperiences: number;
  handleSelectedCountry: CallbackFunction;
  handleSearchedCity: CallbackFunction;
  onApplyFilters: (data: BusinessFilters) => void;
  onClose: CallbackFunction;
  onClearFilters: (data: BusinessFilters) => void;
}

export default function SearchFilterForm({
  filters,
  requestIsLoading,
  countries,
  cities,
  activeExperiences,
  handleSelectedCountry,
  handleSearchedCity,
  onApplyFilters,
  onClose,
  onClearFilters,
}: SearchFilterFormProps) {
  const { t } = useTranslation();

  const [searchedCity, setSearchedCity] = useState('');
  const activeExperiencesText = t('filters.activeExperiences');
  const [beforeCount, afterCount] = activeExperiencesText.split('{{count}}');

  const domains = getBusinessDomainOptions();

  const resetValues = {
    search: '',
    country: { value: '', label: '' },
    city: { value: '', label: '' },
    language: { value: '', label: '' },
    domain: { value: '', label: '' },
  };

  const validationSchema = Yup.object().shape({
    country: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
    city: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),

    domain: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  });
  function findLabel(itemValue: string, options: Option[]) {
    const res = options.find((x) => x.value === itemValue)?.label;
    return res ?? '';
  }

  const defaultInitialValues = {
    search: filters.search,
    country: {
      value: filters.country,
      label: findLabel(filters.country, countries) ?? '',
    },
    city: {
      value: filters.city,
      label: findLabel(filters.city, cities) ?? '',
    },

    domain: {
      value: filters.domain,
      label: findLabel(filters.domain, domains) ?? '',
    },
  };

  const initialValues = defaultInitialValues || resetValues;

  return (
    <SC.Container>
      <SC.HeadingContainer>
        <SC.SearchFilterTitle>{t('filters.title')}</SC.SearchFilterTitle>
        <SC.ActiveExperiences>
          {beforeCount}
          <SC.Highlight>{activeExperiences}</SC.Highlight>
          {afterCount}
        </SC.ActiveExperiences>
        <SC.Description>{t('filters.description')}</SC.Description>
      </SC.HeadingContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const filtersData: BusinessFilters = {
            search: values.search,
            country: values.country,
            city: values.city,
            domain: values.domain,
          };
          onApplyFilters(filtersData);
          onClose();
        }}
      >
        {({ errors, touched, values, setFieldValue, resetForm }) => (
          <SC.FormWrapper>
            <SC.InputsContainer>
              <SC.Section>
                <SC.BoldText>{t(`filters.search`)}</SC.BoldText>
                <SC.InputsContainer>
                  <SC.Input
                    name="search"
                    type="text"
                    placeholder={t(`filters.search`)}
                  />
                </SC.InputsContainer>
              </SC.Section>
              {/* <SC.Section>
                <SC.BoldText>{t(`filters.country`)}</SC.BoldText>
                <SC.CountryDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    optionSelected={{
                      label: values.country.label,
                      value: values.country.value,
                    }}
                    onSelectOption={(option) => {
                      handleSelectedCountry(option);
                      setFieldValue('country', option);
                      setFieldValue('city', {
                        label: '',
                        value: '',
                      });
                    }}
                    options={countries}
                    error={Boolean(errors.country && touched.country)}
                  />
                  {errors.country?.value && touched.country ? (
                    <SC.Error>{errors.country.value}</SC.Error>
                  ) : null}
                </SC.CountryDropdownInputContainer>
              </SC.Section>
              <SC.Section>
                <SC.BoldText>{t(`filters.city`)}</SC.BoldText>
                <SC.CityDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    disabled={!values.country.value}
                    optionSelected={{
                      label: values.city.label,
                      value: values.city.value,
                    }}
                    onInputChange={(value: string) => {
                      handleSearchedCity(value);
                      setSearchedCity(value);
                    }}
                    onSelectOption={(option) => {
                      setSearchedCity('');
                      setFieldValue('city', option);
                    }}
                    options={cities}
                    serverSideSearchValue={searchedCity}
                    isServerSideFiltering
                    error={Boolean(errors.city && touched.city)}
                  />
                </SC.CityDropdownInputContainer>
              </SC.Section> */}

              <SC.Section>
                <SC.BoldText>{t(`filters.domain`)}</SC.BoldText>
                <SC.DomainDropdownInputContainer>
                  <DropdownInput
                    placeholder={t(`placeholders.selectYourChoice`)}
                    optionSelected={{
                      label: values.domain.label,
                      value: values.domain.value,
                    }}
                    onSelectOption={(option) => {
                      setFieldValue('domain', option);
                    }}
                    options={domains}
                  />
                </SC.DomainDropdownInputContainer>
              </SC.Section>
            </SC.InputsContainer>
            <SC.ButtonsWrapper>
              <Button
                type="button"
                color={classic.palette.button.main.marine}
                onClick={() => {
                  onClearFilters(initialValues);
                  resetForm({ values: resetValues });
                }}
              >
                {t(`general.clear`)}
              </Button>
              <Button
                type="submit"
                color={classic.palette.button.main.yellow}
                isLoading={requestIsLoading}
              >
                {t(`general.apply`)}
              </Button>
            </SC.ButtonsWrapper>
          </SC.FormWrapper>
        )}
      </Formik>
    </SC.Container>
  );
}
