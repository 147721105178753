import { lazy } from 'react';
import PATH from 'routes/paths';
import { Route } from 'routes/types';

const ConnectedHowItWorksPage = lazy(
  () =>
    import(
      '../../components/smart/pages/businessPages/howItWorks/ConnectedHowItWorksBusinessPage'
    )
);

const HOW_IT_WORKS: Route = {
  component: ConnectedHowItWorksPage,
  path: PATH.BUSINESS.HOW_IT_WORKS,
  isProtected: false,
};

export default [HOW_IT_WORKS];
