import * as SC from './styles';

export interface ToggleOption<T> {
  label: string;
  value: T;
}

export interface ToggleSwitchProps<T> {
  activeValue: T;
  options: ToggleOption<T>[];
  onToggle: (value: T) => void;
  onToggleEnd?: (value: T) => void;
  getColor?: (value: T) => string;
}
const ToggleSwitch = <T extends string | number>({
  activeValue,
  options,
  onToggle,
  onToggleEnd,
  getColor = () => '#007bff',
}: ToggleSwitchProps<T>) => (
  <SC.ToggleSwitch>
    <SC.ActiveIndicator
      onTransitionEnd={() => onToggleEnd && onToggleEnd(activeValue)}
      activeIndex={options.findIndex((option) => option.value === activeValue)}
      color={getColor(activeValue)}
    />
    {options.map((option) => (
      <SC.ToggleOption
        key={option.value}
        isActive={activeValue === option.value}
        onClick={() => onToggle(option.value)}
      >
        {option.label}
      </SC.ToggleOption>
    ))}
  </SC.ToggleSwitch>
);

export default ToggleSwitch;
