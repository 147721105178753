import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import appRoleReducer from './slices/appViewRole';
import appModeReducer from './slices/appMode';

const store = configureStore({
  reducer: {
    auth: authReducer,
    appRole: appRoleReducer,
    appMode: appModeReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
