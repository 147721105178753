import { useTranslation } from 'react-i18next';

import CalendarIcon from 'assets/icons/CalendarIcon';
import FileIcon from 'assets/icons/invoices/FileIcon';
import LocationPinIcon from 'assets/icons/profileIcons/LocationPinIcon';
import {
  FavoritesIcon,
  MoneyIcon,
  ReferralIcon,
} from 'assets/icons/SideBarMenuIcons/SideBarIcons';
import * as SC from './styles';
import SideBarCard from '../../SideBarCard';

interface ExmindsIndividualActionsProps {
  actions: any;
  isGuestProfile: boolean;
  isAuthenticated: boolean;
  exminderView: boolean;
  hasReferralAccount: boolean;
}

export default function ExmindsIndividualActions({
  actions,
  isGuestProfile,
  isAuthenticated,
  exminderView,
  hasReferralAccount,
}: ExmindsIndividualActionsProps) {
  const { t } = useTranslation();
  const {
    onFavoritesClick,
    onInvoicesClick,
    onMeetingPlacesClick,
    onMoneyWithExmindsClick,
    onMyCalendarClick,
    onReferralDashboardClick,
  } = actions;
  return (
    <SC.InformationSection gap={15}>
      <SideBarCard
        icon={<CalendarIcon />}
        text={t(`sidebarMenu.myCalendar`)}
        onClick={onMyCalendarClick}
        disabled={isGuestProfile}
      />
      <SideBarCard
        icon={<FavoritesIcon />}
        text={t(`sidebarMenu.favorites`)}
        onClick={onFavoritesClick}
        disabled={isGuestProfile}
      />
      {isAuthenticated && exminderView && (
        <SideBarCard
          icon={<MoneyIcon />}
          text={t(`sidebarMenu.moneyWithExminds`)}
          onClick={onMoneyWithExmindsClick}
        />
      )}
      <SideBarCard
        icon={<FileIcon />}
        text={t(`sidebarMenu.invoices`)}
        onClick={onInvoicesClick}
        disabled={isGuestProfile}
      />
      {hasReferralAccount && (
        <SideBarCard
          icon={<ReferralIcon />}
          text={t(`sidebarMenu.exmindsPartners`)}
          onClick={onReferralDashboardClick}
        />
      )}
      <SideBarCard
        icon={<LocationPinIcon />}
        text={t(`sidebarMenu.meetingPlaces`)}
        onClick={onMeetingPlacesClick}
      />
    </SC.InformationSection>
  );
}
