import styled from 'styled-components';
import { zIndex } from '../../../../helpers/constants/zIndexValues';
import { AppMode, SideBarSteps } from '../../../../helpers/constants/enums';
import Button from '../../atomics/Button';
import IconButton from '../../atomics/IconButton';

const background = {
  main: `linear-gradient(180deg, rgba(234, 195, 0, 0.39) 0%, rgba(0, 175, 185, 0.29) 100%)`,
  exminder: `linear-gradient(180deg, #00AFB9 0%, #0081AA 100%)`,
  explorer: `linear-gradient(180deg, rgba(234, 195, 0, 0.40) 0%, rgba(234, 195, 0, 0.78) 62.9%, #EAC300 100%)`,
  business:
    'linear-gradient(158.12deg, rgba(0, 255, 0, 0.11) -3.92%,rgb(0, 185, 126) 153.19%);',
};

const getBackground = (
  step: SideBarSteps,
  appMode: AppMode,
  isExminder: boolean
) => {
  if (step === SideBarSteps.CompleteProfileStep) {
    if (isExminder) {
      return background.exminder;
    }
    return background.explorer;
  }
  if (appMode === AppMode.BUSINESS) {
    return background.business;
  }
  return background.main;
};

export const OverlayContainer = styled.div<{ open: boolean }>`
  height: 100%;
  width: 100%;
  min-width: 320px;
  position: fixed;
  top: 0;
  right: 0;
  backdrop-filter: blur(10px);
  z-index: ${zIndex.sideBarMenuOverlay};
  transition: visibility 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: visibility 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: auto;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? '100%' : '0%')};
`;

export const Container = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  max-width: 480px;
  min-width: 320px;
  z-index: ${zIndex.sideBarMenu};
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: ${({ theme }) => theme.palette.main.white};
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
  transform: ${({ open }) =>
    open ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
`;

export const BackgroundContent = styled.div<{
  step: SideBarSteps;
  appMode: AppMode;
  isExminder: boolean;
}>`
  width: 100%;
  height: 100%;
  background: ${({ step, appMode, isExminder }) =>
    getBackground(step, appMode, isExminder)};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 0;
`;

export const MainContent = styled.div<{ currentStep: number }>`
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ currentStep }) =>
    currentStep === SideBarSteps.MainPageStep ? 'flex-start' : 'center'};
  gap: 20px;
`;

export const IconButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 0 20px;
`;

export const StyledIconButton = styled(IconButton)`
  svg {
    path {
      fill: ${({ theme }) => theme.palette.main.marine};
    }
  }
`;

export const ButtonGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 40px;
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const StyledButton = styled(Button)<{ isExminder: boolean }>`
  max-width: 275px;
  background: ${({ theme, isExminder }) =>
    isExminder
      ? theme.palette.button.main.yellow
      : theme.palette.button.main.marine};
`;

export const StepContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;
