import AdventuresIcon from 'assets/icons/domains/AdventuresIcon';
import BusinessIcon from 'assets/icons/domains/BusinessIcon';
import CreativityIcon from 'assets/icons/domains/CreativityIcon';
import ExpertsIcon from 'assets/icons/domains/ExpertsIcon';
import ExtrasIcon from 'assets/icons/domains/ExtrasIcon';
import HealthcareIcon from 'assets/icons/domains/HealthcareIcon';
import LifestyleIcon from 'assets/icons/domains/LifestyleIcon';
import MindfulnessIcon from 'assets/icons/domains/MindfulnessIcon';
import ParentingIcon from 'assets/icons/domains/ParentingIcon';
import SmallTalkIcon from 'assets/icons/domains/SmallTalkIcon';
import TechnologyIcon from 'assets/icons/domains/TechnologyIcon';
import TravelingIcon from 'assets/icons/domains/TravelingIcon';
import FinanceIcon from 'assets/icons/domains/FinanceIcon';
import HrIcon from 'assets/icons/domains/HrIcon';
import ItIcon from 'assets/icons/domains/ITIcon';
import LawIcon from 'assets/icons/domains/LawIcon';
import MarketingIcon from 'assets/icons/domains/MarketingIcon';
import SalesIcon from 'assets/icons/domains/SalesIcon';
import HandsOnIcon from 'assets/icons/experience/details/HandsOnIcon';
import MentorshipIcon from 'assets/icons/experience/details/MentorshipIcon';
import ShadowingIcon from 'assets/icons/experience/details/ShadowingIcon';
import { ExperienceType, SvgPath } from 'helpers/constants/enums';
import { ReactElement } from 'react';
import { BusinessDomain, Domain } from 'uiModels/types';
import * as SC from './styles';

export const getDomainIcon = (domain: string): ReactElement | null => {
  const iconMap: { [key in Domain]: ReactElement } = {
    business: <BusinessIcon />,
    lifestyle: <LifestyleIcon />,
    experts: <ExpertsIcon />,
    technology: <TechnologyIcon />,
    creativity: <CreativityIcon />,
    traveling: <TravelingIcon />,
    adventures: <AdventuresIcon />,
    parenting: <ParentingIcon />,
    healthcare: <HealthcareIcon />,
    mindfulness: <MindfulnessIcon />,
    small_talk: <SmallTalkIcon />,
    extras: <ExtrasIcon />,
  };

  return iconMap[domain as Domain] || null;
};

export const getBusinessDomainIcon = (
  domain: BusinessDomain
): ReactElement | null => {
  const iconMap: Record<BusinessDomain, ReactElement> = {
    it: <ItIcon />,
    sales: <SalesIcon />,
    marketing: <MarketingIcon />,
    hr: <HrIcon />,
    finance: <FinanceIcon />,
    law: <LawIcon />,
  };

  return iconMap[domain] || null;
};

export const renderExperienceTypeIcon = (typeOfExperience: string) => {
  switch (typeOfExperience) {
    case ExperienceType.HANDS_ON:
      return (
        <SC.ExperienceTypeIconContainer $path={SvgPath.STROKE}>
          <HandsOnIcon />
        </SC.ExperienceTypeIconContainer>
      );
    case ExperienceType.MENTORSHIP:
      return (
        <SC.ExperienceTypeIconContainer $path={SvgPath.FILL}>
          <MentorshipIcon />
        </SC.ExperienceTypeIconContainer>
      );
    case ExperienceType.SHADOWING:
      return (
        <SC.ExperienceTypeIconContainer $path={SvgPath.FILL}>
          <ShadowingIcon />
        </SC.ExperienceTypeIconContainer>
      );
    default:
      return null;
  }
};
