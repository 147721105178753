import {
  channelId,
  contentCreatorSlug,
  contestId,
  experienceId,
  experienceSlug,
  userId,
} from './constants';

const PATH = Object.freeze({
  INDEX: '/',
  BUSINESS_INDEX: '/business',
  AUTH: {
    WELCOME: '/welcome',
    SIGN_IN: '/sign-in',
    SIGN_UP: `/sign-up`,
    VERIFY_EMAIL: `/email/verify`,
    EMAIL_VERIFIED: `/email/verified`,
    FORGOT_PASSWORD: `/password-forgot`,
    RESET_PASSWORD: `email/password-reset`,
    CONFIRM_RESET_PASSWORD: `/password-confirm`,
  },
  ONBOARDING: {
    INDEX: '/onboarding',
    EXPLORE: '/onboarding/explore',
    CHOOSE_ROLE: '/onboarding/choose-role',
    BUILD_PROFILE: '/onboarding/build-profile',
    CHAT: '/onboarding/chat',
    CHOOSE_DOMAINS: '/onboarding/choose-domains',
    CHOOSE_HOBBIES: '/onboarding/choose-hobbies',
    CHOOSE_PROFILE_PICTURE: '/onboarding/choose-profile-picture',
    PROFILE_IS_SET: '/onboarding/profile-is-set',
  },
  EXPERIENCE: {
    VIEW_ONE: `/experience/${experienceId}`,
    VIEW_ONE_BY_SLUG: `/${contentCreatorSlug}/experience/${experienceSlug}`,
    PURCHASE_CONFIRMATION: `/experience/${experienceId}/purchase-confirmation`,
    PAYMENT_ACCEPTED: `/experience/${experienceId}/payment-accepted`,
    PAYMENT_CONFLICT: `/experience/${experienceId}/payment-conflict`,
    START_CONVERSATION: `/experience/${experienceId}/start-conversation`,
    CREATE: `/experience/create`,
    BENEFITS_REQUIREMENTS: `/experience/${experienceId}/benefits-requirements`,
    ACTIONS: `/experience/actions/${experienceId}`,
    DELETE: `/experience/actions/delete/${experienceId}`,
    CANCEL: `/experience/actions/cancel/${experienceId}`,
    UPDATE: `/experience/actions/update/${experienceId}`,
    EDIT_BENEFITS: `/experience/${experienceId}/edit-benefits`,
    EDIT_REQUIREMENTS: `/experience/${experienceId}/edit-requirements`,
    FAVORITES: `/experience/favorites`,
    INVITE_GUEST: `/experience/${experienceId}/invite-guest`,
  },
  EXMINDER: {
    VIEW_PROFILE: `/exminder/${userId}/work-and-education`,
    VIEW_UNIVERSE: `/exminder/${userId}`,
    VIEW_REVIEWS: `/exminder/${userId}/reviews`,
    VIEW_PROFILE_BY_SLUG: `/${contentCreatorSlug}/work-and-education`,
    VIEW_UNIVERSE_BY_SLUG: `/${contentCreatorSlug}`,
    VIEW_REVIEWS_BY_SLUG: `/${contentCreatorSlug}/reviews`,
    MY_EXPERIENCES: `/exminder/my-experiences`,
  },
  STRIPE_REGISTER: {
    INDEX: '/stripe-register',
    AGREEMENT: '/stripe-register/agreement',
    FORM: '/stripe-register/form',
    BUSINESS_FORM: '/stripe-register/business-form',
    SUCCESS: '/stripe/return',
    CONFIRM_INDIVIDUAL_ACCOUNT: '/stripe/confirm-individual-account',
    CONFIRM_BUSINESS_ACCOUNT: '/stripe/confirm-business-account',
  },
  EXPLORER: {
    VIEW_ONE: `/explorer/${userId}`,
    SET_DOMAINS: `/explorer/set-domains`,
    SET_HOBBIES: `/explorer/set-hobbies`,
    MY_EXPERIENCES: `/explorer/my-experiences`,
  },
  FAKE_INFLUENCER: {
    VIEW_ONE: `/influencer/${userId}`,
  },
  ACCOUNT_SETTINGS: {
    INDEX: `/account-settings`,
    SET_ACCOUNT_DETAILS: `/account-details`,
    SET_PERSONAL_DETAILS: `/personal-details`,
    FINANCIAL_REGISTRATION: `/financial-registration`,
    APP_LANGUAGE: `/app-language`,
    SET_FIRST_NAME: `/set-first-name`,
    SET_LAST_NAME: `/set-last-name`,
    SET_PASSWORD: `/set-password`,
    SET_EMAIL: `/set-email`,
    PIN_INPUT_EMAIL: `/pin-input-email`,
    EMAIL_VERIFIED: `/verified-email`,
    SET_PHONE_NUMBER: `/set-phone-number`,
    PIN_INPUT_PHONE_NUMBER: `/pin-input-phone-number`,
    PHONE_NUMBER_VERIFIED: `/verified-phone-number`,
    PHONE_NUMBER_CONFIRMATION: `/confirm-phone-number`,
    DELETE_MY_ACCOUNT: `/delete-my-account`,
  },
  REVIEW: {
    EXPERIENCE_ENDED: `/experience-ended/${experienceId}`,
    RATE_EXPERIENCE: `/rate-experience/${experienceId}`,
    RATE_EXMINDER: `/rate-exminder/${userId}`,
    RATE_EXPLORER: `/rate-explorer/${userId}`,
    EXPERIENCE_REVIEW: `/experience-review/${experienceId}`,
    EXMINDER_REVIEW: `/exminder-review/${userId}`,
    EXPLORER_REVIEW: `/explorer-review/${userId}`,
    REVIEW_UPLOADED: `/review-uploaded`,
  },
  NOTIFICATIONS: {
    NEWS: `/notifications`,
    DEMO_MESSAGES: `/notifications/message/${channelId}`,
    MESSAGES: `/notifications/chat-messages/${channelId}`,
  },
  EXPERIENCE_DATE_CHANGED: {
    DATE_CHANGED: `/experience-date-changed/${experienceId}`,
    DATE_CHANGE_CONFIRMATION: `/experience-date-change-confirmation/${experienceId}`,
    DATE_CHANGE_REJECTED: `/experience-date-change-rejected/${experienceId}`,
    DATE_CHANGE_ACCEPTED: `/experience-date-change-accepted/${experienceId}`,
  },
  EXPERIENCE_JOIN_REQUEST: {
    DECISION: `/experience-decision/${experienceId}`,
    ACCEPTANCE: `/experience-acceptance/${experienceId}`,
  },
  EXPERIENCE_PIN_CODE: {
    PIN: `/experience-get-pin/${experienceId}`,
    VALIDATED: `/experience-pin-validated`,
  },
  REPORT: {
    USER: `/report-user/${userId}`,
    USER_REPORTED_CONFRIMATION: `/report-user-confirmation/${userId}`,
    EXPERIENCE: `/report-experience/${experienceId}`,
  },
  TERMS_AND_CONDITIONS: `/terms-and-conditions`,
  PRIVACY_POLICY: `/privacy-policy`,
  INVOICES: `/invoices`,
  COMMUNITY_GUIDELINES: `/community-guidelines`,
  FAQ: `/frequently-asked-questions`,
  MONEY_WITH_EXMINDS: `/money-with-exminds`,
  HOW_IT_WORKS: `/how-it-works`,
  SEND_FEEDBACK: `/send-feedback`,
  FEEDBACK_SENT_CONFIRMATION: `/feedback-sent-confirmation`,
  MIND_FEED: `/mind-feed`,
  REFERRAL_DASHBOARD: `/exminds-partners`,
  MEETING_PLACES: `/meeting-places`,
  ERROR: {
    PROFILE_NOT_FOUND: '/profile-not-found',
    EXPERIENCE_NOT_FOUND: '/experience-not-found',
  },
  CONTEST: {
    RULES: `/contest/${contestId}/rules/${experienceId}`,
    FINAL_STEP: `/contest/${contestId}final-step/${experienceId}`,
  },
  BUSINESS: {
    PROFILE: {
      MISSING_INFO: `/business/profile-missing-info/${userId}`,
      UPLOAD_CV: `/business/upload-cv/${userId}`,
      UPLOAD_VIDEO: `/business/upload-video/${userId}`,
      COMPLETED: `/business/profile-complete/${userId}`,
    },
    WAITING_LIST: `/business/waiting-list`,
    EXPERIENCE_VIEW_ONE: `/business/experience/${experienceSlug}`,
    HOW_IT_WORKS: '/business/how-it-works',
  },
});

export default PATH;
